#red-circle {
}

.blob {
}

#recwin1 {
  /* display: none; */
}

#anyTime {
  padding-right: 30px;
}

.cir {
  background: #f00;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  background: #f00;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: left;
  margin-top: 14px;
  margin-left: 5px;
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* scrollbarfix */

.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e7e7e7;
  box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #e7e7e7;
  box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #fafafa;
  width: 12px;
  height: 12px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #c1c1c1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 4px 3px 3px 4px; /* Workaround because margins aren't supported */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/* scrollbarfix end */

.block {
  display: block;
}
.callendarHolder {
  display: inline-flex;
  width: 100%;
}
.calColumn {
  width: 100%;
  border: #ccc solid 0.1px;
}
.calSpacer {
  margin: 5px;
  margin-left: 55px;
  margin-right: 55px;
}
.calElement {
  height: 200px;
}
.scheduleHolder {
  transform: scale(0.75);
  max-width: 671px;
  position: relative;
  bottom: -80px;
  right: -575px;
  margin: 0px !important;
}
.datePickerHolder {
  display: inline-flex;
  width: 100%;
}
.timePickerHolder {
  margin: 0px auto;
  /* padding-left: 5px; */
  width: 50%;
}
@media screen and (max-width: 800px) {
  .timePickerHolder {
    padding-left: 0px;
    width: 100%;
    height:50px;
  }
}
.MuiDateTimePickerToolbar-timeContainer{
  margin-top: auto;
  margin-bottom: 1.5px;
  

}
.PrivatePickersToolbarText-root{
  font-size: 2.125rem !important;
}
.MuiDateTimePickerToolbar-separator{
  font-size: 2.125rem !important;
}

.MuiClock-pmButton{
  position: inherit !important;
}
.MuiClock-amButton{
  position: inherit !important;
}
.MuiClockPicker-root{
  margin-top: 6px !important;
  margin-bottom: 16px !important;
}
.MuiClock-root{
  display: inline !important;
  height: 240px !important;
}
.MuiPickerStaticWrapper-root .MuiDialogActions-root{
  display:none;
}
.MuiClockPicker-root{
  width:auto !important;
}
.MuiDayPicker-slideTransition{
  min-height: 240px !important;
  height: 240px !important;
}
.MuiCalendarPicker-viewTransitionContainer{
  height: 274px !important;
  overflow: hidden;

}
.margin-help {
  margin: 15px;
}
.tutorialImage {
  width: 100%;
}
#settingsButton {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#newAvatarButton {
  margin-left: 20px;
  margin-top: -3px;
  font-weight: 600;
  font-size: 11px;
}
.prevImg {
  height: 55px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.vid-border {
  position: relative;
  /*
     *
     * border: 5px solid blue; */
  width: 100%;
  overflow: hidden;
  background-color: black;
  border-radius: 7px;
  border-radius: 10px 10px 10px 10px;
  padding-bottom: 75%;
}
video {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.vidPreviewLabel {
  margin-top: 10px;
}
.videoPreview {
  margin-top: 10px;
  top: 0px;
  left: 0px;
  width: 720px;
  height: 360px;
  position: relative;
}

.videoholder {
  display: block;
}

.topbar {
  height: 30px;
  width: 580px;

  /*
  background: #202020;
  border-radius: 10px 10px 0 0;
  */
}
.toolBar {
  padding-right: 24px;
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: -16px;

  display: flex;
  position: relative;
  align-items: center;
  box-sizing: inherit;
}
@media screen and (max-width: 530px) {
  .homePaper {
    margin: 0px !important;
    margin-top: 16px !important;
  }
}

.minus {
  position: relative;
  margin-left: 15px;
  top: 2px;
  float: left;
}

.glyphicon-minus {
  font-size: 100px;
}

.video-in-sharing-container__self-video {
  width: 33.3%;
  height: 33.3%;
  position: absolute;
  right: 4px;
  bottom: 4px;
  overflow: hidden;
  border-radius: 7px;
}

.video-in-sharing-container__self-video--hide {
  display: none;
}
.video-in-sharing-container__self-video--spotlight {
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
}

.video-in-sharing-container__self-canvas {
  width: 100%;
  height: 100%;
}

.video-in-sharing-container__avatar-name {
  max-width: 90%;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.video-in-sharing-container__avatar-footer {
  position: absolute;
  max-width: 50%;
  left: 0;
  bottom: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  padding: 4px 6px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  border-radius: 0 7px 0 0;
}

.video-in-sharing-container__avatar-img {
  max-width: 90%;
  max-height: 90%;
}

.video-in-sharing-container__avatar-name {
  max-width: 90%;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-in-sharing-container__avatar-footer {
  position: absolute;
  max-width: 50%;
  left: 0;
  bottom: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  padding: 4px 6px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  border-radius: 0 7px 0 0;
}

.self-video-container__talking {
  background: rgba(32, 32, 32, 0.8);
  color: #fff;
  font-size: 12px;
  width: 200px;
  padding: 10px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  cursor: move;
}

.self-video-container__video {
  background: rgba(32, 32, 32, 0.8);
  color: #fff;
  font-size: 12px;
  cursor: move;
  position: relative;
}

.self-video-container__video-1 {
  width: 240px;
  height: 135px;
}
.self-video-container__video-2 {
  width: 480px;
  height: 270px;
}

.self-video-container__canvas {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}
.paperPadding {
  padding: 16px;
}
.left {
  text-align: left;
}
.webinarBanner {
  max-width: -webkit-fill-available;
}
.image {
  --x: 0px;
  --y: 0px;
  background-image: url(https://images.unsplash.com/photo-1499162526249-f5b8b9ba9923?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=865bf5c0b77ceb22e88de9fd41c5a671);
  background-size: 1000px;
  background-position: var(--x) var(--y);
  width: 100%;
  height: 500px;
}
.inline {
  display: inline-flex;
}
.inlineRight {
  display: inline-flex;
  justify-content: right;
  margin-left: auto;
}
.notesHolder {
  width: 250px;
}
.rightContainer {
  display: inline-flex;
  width: 100%;
}
.block {
  display: block;
}
.logo {
  height: 28px;
  margin: auto;
  /* margin-left: -95px; */
}

.cron_builder {
  min-width: 100%;
}

.cron_builder_bordering {
  border: 1px solid #ddd;
  border-top: none;
  text-align: center;
  padding: 10px;
  background: #fff;
}
.cron_builder_bordering input,
.cron_builder_bordering select {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  padding-left: 5px;
  cursor: pointer;
}

.df {
  display: flex;
}
.cron-builder-bg {
  background-color: #086090;
  color: white;
  text-align: center;
  margin-bottom: 4px;
  padding: 8px 0px;
}
.cron_builder_bordering select {
  background-color: white;
  width: 75px;
  cursor: pointer;
  padding: 4px 0px;
  border-radius: 4px;
}
.cron_builder_bordering select option:hover {
  background-color: #086090;
}
.well-small input {
  width: auto !important;
}
.cron_builder_bordering input[type="radio"] {
  margin-top: 0px;
  vertical-align: middle;
}
.cron_builder {
  border: 1px solid #d0cbcb;
  padding: 5px;
  background-color: #dddef13d;
  width: 50%;
}
.text_align_left {
  text-align: left;
}
.nav li {
  cursor: pointer;
}
.flex {
  display: flex;
}
.flex2 {
  display: flex;
  width: 35%;
}
.settingsFooter {
  margin-top: 8px;
  margin-bottom: 24px;
}
.input {
  margin: 5px;
}
.textholder {
  max-width: 100px;
  overflow-wrap: break-word;
}
.submitConfirm {
  padding: 16px;
}
.submitConfirm2 {
  padding: 16px;
  height: 85vh;
  overflow: scroll;
}

#minutes {
  margin-top: 10px;
}

#submitConfirm {
  margin: auto auto;
  width: 650px;
  max-width: 100vw;
  z-index: 7;
  position: relative;
  height: auto;
}
#submitConfirm2 {
  margin: auto auto;
  width: 915px;
  max-width: 100vw;
  z-index: 7;
  position: relative;
  height: auto;
}
#submitConfirmHolder {
  position: relative;

  height: 0px;
  z-index: 2;
}
.MuiButton-containedPrimary {
  text-transform: unset !important;
}
.MuiButton-containedSecondary {
  text-transform: unset !important;
}

.spiderStatus {
  margin-top: 18px;
  padding-right: 2px;
  margin-left: -2px;
  width: 0px;
  display: block;
}
.alignRight {
  width: auto;
  margin-left: auto;
  display: inline-flex;
}
.alignRight2 {
  width: auto;
  margin-left: auto;
  display: block;
}
.checkBoxHolder {
  height: 20px;
  width: 20px;
  margin-top: -5px;
  margin-left: -8px;
}
.width100 {
  width: 100%;
  display: flex;
}
.vidPreviewHolder {
  width: 100%;
  display: flex;
}
.vidPreview {
  margin: 0px 2px;
  margin-bottom: 16px;
  width: 170px;
  height: 128px;
  display: block;
}
.vidPreviewOverlay {
  height: 40px;
  margin-top: -94px;
  margin-left: 2px;
}
#confirmPop {
  background-color: #000000b8;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0px;

  width: auto;
  z-index: 2;
  margin-left: auto;
  pointer-events: none;
}
.closePopup {
  margin-left: 95.5%;
  margin-bottom: -14px;
}
.noMarginHeader {
  margin-top: 0px;
  margin-bottom: 25px;
}
.smallMarginUnderline {
  margin: 4px 18px;
  text-decoration: underline;
}
.smallMarginNoUnderline {
  margin: 4px 18px;
  text-decoration: none;
}
.previewImageContainer {
  width: 172px;
  height: 167px;
  margin-top: -20px;
}
.smallNamePreviewHolder {
  margin: 0px;
  margin-left: 3px;
  color: white;
  background: #00000099;
  position: relative;
  top: 128px;
  width: auto;
  display: inline-flex;
}
.smallNamePreview {
  margin: 0px 4px;
  font-size: 12px;
}
.namePreview {
  margin: 0px 4px;
  font-size: 18px;
  color: white;
  text-align: center;

  position: relative;
  top: 78px;
}
.popupText {
  text-align: center;
  width: 100%;
  font-size: 24px;
  margin-left: -96px;
}
.messageHolder {
  max-width: 40vw;
  overflow-wrap: break-word;
}
.loading {
  margin: auto auto;
  z-index: 7;
  height: 0px;
  width: 530px;
  height: 100%;
}
.loadingPositioner {
  padding-top: 300px;
  width: 45px;
  margin: auto;
}
.loadingPositioner2 {
  width: 0px;
  height: 30px;
  margin: auto;
}
.loadingPositioner3 {
  padding-top: 2%;
  width: 45px;
  margin: auto;
}
.loadingMessage {
  margin: auto;
  margin-left: 16px;
  font-size: 16px;
}
.confirmSpacer {
  width: 650px;
  margin: auto;
}
.confirmSpacer2 {
  width: 915px;
  margin: auto;
}
.confirmHolder {
  position: absolute;
  /* left: 0; */
  /* right: 0; */
  /* width: 100%; */
  top: 0px;
  bottom: 0px;
  /* width: auto; */
  margin-left: auto;
}
.confirmHolder2 {
  margin-top: 70px;
  z-index: 10;
}

.saleTitle {
  font-size: 22px;
  display: inline-flex;
}
.saleText {
  font-size: 14px;
  color: #2d2d2d;
  display: inline;
}
.tierSpacer {
  margin-bottom: 30px;
  margin-left: 32px;
  max-height: 32px;
}
.productText{
  width: 100%;
  margin-top: 8px;
  /* margin-left: 8px; */
  right: -12px;
  position: relative;
  font-size: 18px;
  height: 10px;
}

.tierText {
  font-size: 1.6em;
  display: inline-flex;
}
.tierText2 {
  font-size: 16px;
  display: inline-flex;
}
.priceText {
  font-size: 16px;
  color: #444;
  margin-top: auto;
  margin-bottom: 0px;
  display: inline-flex;
  min-width: 50px;
}
.priceText2 {
  margin-top: 15px;
  margin-bottom: -10px;
  font-size: 20px;
}
.priceDesc {
  font-size: 0.8em;
}
.moneyBack {
  font-size: 1.2em;
  margin-top: 6px;
  justify-self: left;

  text-align: left;
}
.savingsTag {
  margin-top: 10px;
  margin-bottom: 5px;
  color: #fff;
  background-color: green;
  font-size: 12.5px;
  padding: 1px 8px;
}
.redPrice {
  background-color: red;
  position: relative;
  z-index: 1;
  width: 62px;
  height: 4px;
  margin-top: -20px;
  margin-bottom: 16px;
  margin-left: -4px;
}
.carouselImage {
  width: 60%;
  margin-left: 40%;
  opacity: 0;
  pointer-events: none;
}
.carouselText {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -45%;
  /* width: 90%; */
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  text-align: left;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  text-shadow: 2px 2px #222;
  font-size: 32px;
  width: 30%;
}
.carouselText2 {
  transition: all 0.5s ease-in-out;
  top: 12px;
  left: 26px;
  /* left: 100px; */
  width: 98%;
  border-radius: 10px;
  color: #000;
  padding: 0px;
  text-align: left;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  text-shadow: 2px 2px #eee;
  font-size: 28px;
  position: relative;
  max-width: 100%;
  /* height: 0px; */
}
.cButton {
  transition: all 0.5s ease-in-out;
  top: 12px;
  margin-left: 28px !important;
  left: 0%;
  /* width: 90%; */
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  text-align: left;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  text-shadow: 2px 2px #222;
  font-size: 32px;
  position: relative;
  height: 37px;
}
.cButton2 {
  transition: all 0.5s ease-in-out;
  top: 130px;
  left: 22%;
  /* width: 90%; */
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  text-align: left;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  text-shadow: 2px 2px #222;
  font-size: 32px;
  position: relative;
}

.fullDrawer {
  z-index: 1;
}
@media screen and (min-width: 530px) {
  .smallDrawer {
    display: none;
  }
}
@media screen and (max-width: 530px) {
  .fullDrawer {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .carouselText {
    font-size: 24px;
  }
  /* .carouselText2 {
    font-size: 24px;
    top: 83px;
  } */
  /* .cButton {
    top: 160px;
    left: -150px;
  } */
  .cButton2 {
    /* top: 160px;
    left: -150px; */
  }
}
@media screen and (max-width: 720px) {
  .carouselText {
    font-size: 16px;
  }
  /* .carouselText2 {
    font-size: 16px;
  } */
  .cButton {
    /* top: 115px; */
  }
  .cButton2 {
    /* top: 115px; */
  }
}
@media screen and (max-width: 700px) {
  .carouselText {
    font-size: 16px;
  }
  /* .carouselText2 {
    font-size: 16px;
  } */
  .cButton {
    /* left: 50px; */
  }
  .cButton2 {
    /* left: -100px; */
  }
}
@media screen and (max-width: 530px) {
  .carouselText {
    font-size: 12px;
  }
  /* .carouselText2 {
    top: 52px;

    font-size: 14px;
  } */
  .cButton {
    /* left: 50px; */
  }
  .cButton2 {
    /* left: -100px; */
  }
  .carouselImage {
    margin-left: 55%;
  }
}
.buttonHolder {
  height: 0px;
  top: 15%;
}
.cImage {
  width: 100%;
  /* top: -25px; */
  display: relative;
}
.cHolder {
  padding-top: 28px;
  /* overflow: hidden; */
  justify-self: right;
  margin-left: auto;
}
.warningText {
  font-size: 16px;
}
.buttonHolder {
  height: 0px;
  top: 15%;
}
.carouselSlide {
  display: inline;
}

.carousel .thumbs {
  display: none;
}

.carousel.carousel-slider {
  display: block;
}

/* .carousel img {
  bottom: 85px;
  vertical-align: top;
  position: relative;
} */
.carousel .slider-wrapper.axis-horizontal .slider .slide div {
  background-color: #fff;
  /* height: 400px; */
}

.carousel {
  /* height: 400px; */
  background-color: #fff;
  display: none;
  border: 0px;
}
.slide {
  background-color: #fff;
}
.carousel.carousel-slider .control-arrow {
  background-color: rgba(255, 255, 255, 0.3) !important;
  opacity: 0.7 !important;
  transition: all 0.25s ease-in !important;
}

.carousel .control-arrow:hover {
  background-color: rgba(255, 255, 255, 0.6) !important;
  opacity: 1 !important;
}
.control-prev:before {
  color: black !important;
  opacity: 1 !important;
  border-right: 12px solid #000 !important;
}
.control-next:before {
  color: black !important;
  opacity: 1 !important;
  border-left: 12px solid #000 !important;
}

.App {
  width: 90%;
  max-width: 700px;
  margin: 2em auto;
}
p.lorem-text {
  text-align: justify;
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

#secondButton {
  margin-left: 15px;
  margin-bottom: 5px;
}

#startStopButton {
  margin-left: 15px;
  margin-bottom: 5px;
}

#showcamform {
  margin-left: 0px;
  margin-right: 10px;
}

.modal-area {
  margin-top: 60px;
  margin-bottom: 40px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*
  padding: 2.5em 1.5em 1.5em 1.5em;
  */
  padding: 1em 1.5em 0.5em 1.5em;

  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#timeHolder {
}
#avatarText {
}

#recwin1 .modal-area {
  padding: 1em 1.5em 0.5em 1.5em;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 47em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
  margin-bottom: 50px;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}
.checkerUpsell {
  font-size: 17px;
  text-align: center;
}
#outlined-basic2 {
  width: 150px;
  margin-top: -5.6px;
}
